.react-checkbox-tree {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  font-size: 16px;
}

.react-checkbox-tree > ol {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.react-checkbox-tree ol {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.react-checkbox-tree ol ol {
  padding-left: 24px;
}

.react-checkbox-tree button {
  line-height: normal;
  color: inherit;
}

.react-checkbox-tree button:focus {
  outline: none;
}

.react-checkbox-tree button:disabled {
  cursor: not-allowed;
}

.react-checkbox-tree .rct-bare-label {
  cursor: default;
}

.react-checkbox-tree label {
  margin-bottom: 0;
  cursor: pointer;
}

.react-checkbox-tree label:hover {
  background: rgba(51, 51, 204, 0.1);
}

.react-checkbox-tree label:active {
  background: rgba(51, 51, 204, 0.15);
}

.react-checkbox-tree:not(.rct-native-display) input {
  display: none;
}

.react-checkbox-tree.rct-native-display input {
  margin: 0 5px;
}

.react-checkbox-tree .rct-icon {
  font-family: "FontAwesome";
  font-style: normal;
}

.rct-disabled > .rct-text > label {
  opacity: .75;
  cursor: not-allowed;
}

.rct-disabled > .rct-text > label:hover {
  background: transparent;
}

.rct-disabled > .rct-text > label:active {
  background: transparent;
}

.rct-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.rct-options {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  margin-left: .5rem;
  text-align: right;
}

.rct-option {
  opacity: .75;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0 4px;
  font-size: 18px;
}

.rct-option:hover {
  opacity: 1;
}

.rct-option + .rct-option {
  margin-left: 2px;
}

.rct-collapse,
.rct-checkbox,
.rct-node-icon {
  padding: 0 5px;
}

.rct-collapse *,
.rct-checkbox *,
.rct-node-icon * {
  display: inline-block;
  margin: 0;
  width: 14px;
}

.rct-collapse {
  border: 0;
  background: none;
  line-height: normal;
  color: inherit;
  font-size: 12px;
}

.rct-collapse.rct-collapse-btn {
  cursor: pointer;
}

.rct-collapse > .rct-icon-expand-close {
  opacity: .5;
}

.rct-collapse > .rct-icon-expand-close:hover {
  opacity: 1;
}

.rct-native-display .rct-checkbox {
  display: none;
}

.rct-node-clickable {
  cursor: pointer;
}

.rct-node-clickable:hover {
  background: rgba(51, 51, 204, 0.1);
}

.rct-node-clickable:focus {
  outline: 0;
  background: rgba(51, 51, 204, 0.2);
}

.rct-node-icon {
  color: #33c;
}

.rct-title {
  padding: 0 5px;
}

.rct-icon-expand-close::before {
  content: "\f054";
}

.rct-icon-expand-open::before {
  content: "\f078";
}

.rct-icon-uncheck::before {
  content: "\f096";
}

.rct-icon-check::before {
  content: "\f046";
}

.rct-icon-half-check::before {
  opacity: .5;
  content: "\f046";
}

.rct-icon-leaf::before {
  content: "\f016";
}

.rct-icon-parent-open::before {
  content: "\f115";
}

.rct-icon-parent-close::before {
  content: "\f114";
}

.rct-icon-expand-all::before {
  content: "\f0fe";
}

.rct-icon-collapse-all::before {
  content: "\f146";
}
